import isJwtExpired from "../constant/isJwtExpired";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import { ContextValueType, PropsType } from "../types/index";

// import isJwtExpired from 'constants/isJwtExpired';

export const AuthContext = createContext<ContextValueType>({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<PropsType> = ({ children }) => {
  const location = useLocation();
  const [, setCookie, removeCookie] = useCookies(["merchant-token"]);
  const [token, setToken] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState<string>("/dashboard");
  const [resetEmail, setResetEmail] = useState<string>("");
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [bnNumber, setBnNumber] = useState("");
  const [identityUrl, setIdentityUrl] = useState("");
  const [addressUrl, setAddressUrl] = useState("");
  const [cacUrl, setCacUrl] = useState("");
  const [businessVerified, setBusinessVerified] = useState(false);
  const [documentNotloaded, setDocumentNotloaded] = useState(false);
  const [activeStatus, setActiveStatus] = useState<number>(1);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("merchant-token");
    setToken(isJwtExpired(token) ? "none" : token);
  }, []);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setActiveStatus(1);
    } else if (location.pathname === "/wallet") {
      setActiveStatus(2);
    } else if (location.pathname === "/payment") {
      setActiveStatus(3);
    } else if (location.pathname === "/activity") {
      setActiveStatus(4);
    } else if (location.pathname === "/sales") {
      setActiveStatus(5);
    } else {
      setActiveStatus(1);
    }
  }, [activeStatus, location.pathname]);

  // useEffect(() => {
  //   if (!sellPage) {
  //     setCryptoPages(1);
  //   }
  // }, [sellPage]);

  const authenticate = async (token: string) => {
    try {
      setCookie("merchant-token", token);
      setToken(token);
      return Promise.resolve("");
    } catch (error) {
      removeCookie("merchant-token");
      return null;
    }
  };

  const logout = () => {
    removeCookie("merchant-token");
    setToken("none");
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        authenticate,
        logout,
        page,
        setPage,
        resetEmail,
        setResetEmail,
        uploadModal,
        setUploadModal,
        bnNumber,
        setBnNumber,
        identityUrl,
        setIdentityUrl,
        addressUrl,
        setAddressUrl,
        cacUrl,
        setCacUrl,
        setDocumentNotloaded,
        documentNotloaded,
        businessVerified,
        setBusinessVerified,
        successModal,
        setSuccessModal,
        isOpen,
        setIsOpen,
        activeStatus,
        setActiveStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

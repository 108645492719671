import React, { useEffect, useState } from "react";
import { smallLogo } from "../assets/images";
import {
  AiOutlineCheckCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";

import Register from "./Register";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";
import { BiErrorCircle } from "react-icons/bi";
import platform from "platform";

import { TailSpin } from "react-loader-spinner";

const Login = () => {
  const location = useLocation();
  const { theme } = useAuth();
  const { state } = location;
  const { from = "/dashboard" } = state || {};
  const { authenticate, token } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const validateEmail = (email: string, password: string) => {
    // check if email is empty
    if (!email) {
      setError("Kindly tell us your mail");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }
    // check if email is in the correct format using a regular expression
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      setError("Your email is not in the correct format");
      setTimeout(() => {
        setError("");
      }, 6000);
      return false;
    }
    // check if password is empty
    if (!password) {
      setError("We need your password to proceed");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }

    setError("");
    return true;
  };

  useEffect(() => {
    if (token && token !== "none") navigate("/dashboard");
  }, [token]);

  const getUniqueId = () => {
    // Generate a pseudo-unique identifier based on user agent and screen dimensions
    const uniqueId = `${navigator.userAgent}-${window.screen.width}x${window.screen.height}`;
    return uniqueId;
  };

  const getDeviceName = () => {
    // Use navigator.userAgent to get information about the browser
    const userAgent = navigator.userAgent;

    // Detect the browser name based on the userAgent string
    let browserName;

    if (userAgent.indexOf("Firefox") !== -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") !== -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      browserName = "Apple Safari";
    } else if (userAgent.indexOf("Edge") !== -1) {
      browserName = "Microsoft Edge";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      userAgent.indexOf("Trident/") !== -1
    ) {
      browserName = "Internet Explorer";
    } else {
      browserName = "Unknown Browser";
    }

    return browserName;
  };
  const getOperatingSystem = () => {
    let os = platform.os;

    return os;
  };
  const uniqueId = getUniqueId();
  const deviceName = getDeviceName();
  const operatingSystem = getOperatingSystem();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email, password)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      axios
        .post(`${API_URL}api/auth/check-user`, {
          email,
        })
        .then((r: any) => {
          const emailAuth = r.data.data.email2FA;
          const authApp = r.data.data.authApp2FA;

          if (
            r.data.data.email2FA === false &&
            r.data.data.authApp2FA === false
          ) {
            axios
              .post(`${API_URL}api/auth/signin`, {
                email,
                password,
                deviceInfo: {
                  name: deviceName,
                  uniqueId: uniqueId,
                  os: operatingSystem?.family,
                },
              })
              .then((r: any) => {
                setLoading(false);
                setSuccess("You are logged in. Happy trading!!!");
                setTimeout(() => {
                  authenticate(r.data.data.token);
                  setSuccess("");
                  navigate(from);
                }, 2500);
                setTimeout(() => {
                  setError("");
                }, 6000);
              })
              .catch((e: any) => {
                setError(e.response.data.message);
                setLoading(false);
                setTimeout(() => {
                  setError("");
                }, 6000);
                console.log(e.response.data.message);
              });
          }
          if (
            r.data.data.email2FA === true &&
            r.data.data.authApp2FA === false
          ) {
            axios
              .post(`${API_URL}api/auth/signin`, {
                email: email,
                password: password,
                "2faMethod": "email",
                deviceInfo: {
                  name: deviceName,
                  uniqueId: uniqueId,
                  os: operatingSystem?.family,
                },
              })
              .then((r: any) => {
                setLoading(false);
                navigate("/2fa-auth", {
                  state: {
                    email: email,
                    password: password,
                    from: from,
                    authApp: authApp,
                    emailAuth: emailAuth,
                  },
                });
              })
              .catch((e: any) => {
                setLoading(false);
                setError(e.response.data.message);
                setTimeout(() => {
                  setError("");
                }, 6000);
                console.log(e.response.data.message);
              });
          }
          if (
            r.data.data.email2FA === false &&
            r.data.data.authApp2FA === true
          ) {
            axios
              .post(`${API_URL}api/auth/signin`, {
                email: email,
                password: password,
                "2faMethod": "auth-app",
                deviceInfo: {
                  name: deviceName,
                  uniqueId: uniqueId,
                  os: operatingSystem?.family,
                },
              })
              .then((r: any) => {
                setLoading(false);
                navigate("/2fa-auth-app", {
                  state: {
                    email: email,
                    password: password,
                    from: from,
                    authApp: authApp,
                    emailAuth: emailAuth,
                  },
                });
              })
              .catch((e: any) => {
                setLoading(false);
                setError(e.response.data.message);
                setTimeout(() => {
                  setError("");
                }, 6000);
                console.log(e.response.data.message);
              });
          }
          if (
            r.data.data.email2FA === true &&
            r.data.data.authApp2FA === true
          ) {
            axios
              .post(`${API_URL}api/auth/signin`, {
                email: email,
                password: password,
                "2faMethod": "auth-app",
                deviceInfo: {
                  name: deviceName,
                  uniqueId: uniqueId,
                  os: operatingSystem?.family,
                },
              })
              .then((r: any) => {
                setLoading(false);
                navigate("/2fa-auth-app", {
                  state: {
                    email: email,
                    password: password,
                    from: from,
                    authApp: authApp,
                    emailAuth: emailAuth,
                  },
                });
              })
              .catch((e: any) => {
                setLoading(false);
                setError(e.response.data.message);
                setTimeout(() => {
                  setError("");
                }, 6000);
                console.log(e.response.data.message);
              });
          }
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="w-full lgss:h-full font-manrope  pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="flex w-full pt-16 pb-10 px-4 lgss:pb-0 lgss:pt-0 flex-col justify-center lgss:px-28  items-center lgss:items-start lg:px-[150px]">
        <h2 className="text-text_black  font-sora text-2xl font-bold pt-4 lgss:pt-0">
          👋🏽 Welcome Back!
        </h2>
        <h4 className="font-manrope  text-text_black font-medium text-sm pt-6">
          Sign in lets begin seamless trading.
        </h4>
        <form onSubmit={handleSubmit} className="mt-2   w-10/12 lgss:w-full">
          {success && (
            <div className="bg-green-600 w-full text-white  text-[14px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}
          <label className="relative">
            <input
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              name="email"
              className="h-[50px]  w-full mt-8  px-[16px] font-manrope border-2 rounded-lg border-[#4A4A68] outline-none focus:border-blue-600 text-[#4A4A68] transition duration-200"
            />
            {/* <span className="text-base  mt-8 text-[#4A4A68] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
              Email
            </span> */}
          </label>
          <label className="relative  flex items-center ">
            <input
              type={viewPassword ? "text" : "password"}
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              className="h-[50px]  w-full mt-8 px-[16px] font-manrope border-2 rounded-lg border-[#4A4A68] outline-none focus:border-blue-600 text-[#4A4A68] transition duration-200"
            />
            {/* <span className="text-base mt-8 text-[#4A4A68] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
              Password
            </span> */}
            {!viewPassword ? (
              <AiOutlineEyeInvisible
                className="text-3xl -ml-10 mt-[30px] text-secondary_button "
                onClick={() => setViewPassword((prev) => !prev)}
              />
            ) : (
              <AiOutlineEye
                className="text-3xl -ml-10 mt-[30px] text-secondary_button "
                onClick={() => setViewPassword((prev) => !prev)}
              />
            )}
          </label>
          {error && (
            <div className="bg-red-600 text-white mb-4 text-[14px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <h2
            onClick={() => navigate("/forgot-password")}
            className=" mt-4 cursor-pointer  font-bold font-manrope text-[#0B84CB]"
          >
            Forgot password?
          </h2>
          <button
            type="submit"
            className="w-full h-[60px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
        <h4 className="font-manrope  text-text_black font-medium text-sm pt-6">
          Don’t have an account?{" "}
          <span
            onClick={() => {
              navigate("/signup");
            }}
            className="underline text-secondary_button cursor-pointer"
          >
            Sign Up
          </span>
        </h4>
      </div>
    </div>
  );
};

const SignInPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <Login />
  </div>
);
export default SignInPage;

import React from "react";
import { comingSoon } from "../assets/images";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Wallet = () => {
  return (
    <div className="w-full font-manrope">
      <Navbar />
      <div className="lgss:flex  lgss:flex-row font-manrope bg-[#F1F3F5]  h-full">
        <Sidebar />
        <div className="w-full h-full pt-16 px-6 pb-20  font-manrope  lgss:px-20  lgss:w-5/6">
          <h2 className="font-sora text-[24px] font-semibold text-[#3A4852]">
            Wallets
          </h2>
          <div className="w-full h-[620px] lgss:h-[600px] mb-20 px-6 lgss:px-20 lgss:mb-0 mt-20 pt-16 lgss:w-11/12 flex flex-col items-center justify-start  bg-white rounded-xl ">
            <img src={comingSoon} alt="" />
            <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
              Coming Soon
            </h2>
            <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
              We are cooking something great for you. But you can join our
              WhatsApp group consisting of over 1,500 merchants worldwide to get
              exclusive benefits.
            </p>
            <a
              href="https://chat.whatsapp.com/K6wZTRSrG4GJM0jdCbRms4"
              target="_blank"
              rel="noreferrer"
              className="w-[180px] rounded-xl h-[48px] flex items-center justify-center mt-4  text-[12px]  font-medium border border-secondary_button text-secondary_button"
            >
              Join WhatsApp Community
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;

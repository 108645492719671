import React from "react";
import { logo, newCrypto } from "../assets/images";

const Register = () => {
  return (
    <div className="hidden lgss:flex font-manrope flex-col w-2/5 px-[45px] pt-[25px]  bg-hero bg-cover text-white">
      <div>
        <img src={logo} className="w-[110px] h-[29px]" alt="logo" />
      </div>
      <div className="w-full border border-gray-800 h-[350px] lgss:h-[370px] lg:h-[350px] mx-auto  mt-72 lg:mt-52 rounded-xl bg-[#010D16] ">
        <img src={newCrypto} alt="" className="rounded-t-xl" />
        <div className="w-full px-8 mt-4 text-[#60B7F6] text-[14px]">
          NO LIMITS
          <h4 className="text-[18px] font-bold mt-1 text-white">
            Receive payments in Cryptocurrency
          </h4>
          <p className="font-medium text-[#617889] mt-1 text-[14px]">
            Get rid of all forms of payment limitations by opening a gateway to
            receive payments in Cryptocurrency from customers. Receive Bitcoin,
            Ethereum or USDT where there is no cash.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineBars3 } from "react-icons/hi2";
import { IoNotifications, IoSettingsOutline } from "react-icons/io5";
import { useAuth } from "../context/auth-context";
import { smallLogo } from "../assets/images";
import { IoIosNotificationsOutline } from "react-icons/io";

const Navbar = () => {
  const location = useLocation();
  const { setIsOpen } = useAuth();
  return (
    <div className="flex justify-between font-manrope border-b items-center shadow bg-white pt-4 pb-4  lgss:pt-4 lgss:w-full  px-5 lgss:px-12">
      <div className="flex justify-center items-center gap-4 lgss:gap-2 ">
        <HiOutlineBars3
          className=" text-black lgss:hidden text-[32px]"
          onClick={() => setIsOpen((prev: any) => !prev)}
        />
        <div className="">
          <img
            src={smallLogo}
            className="w-[90px] h-[25px] hidden lgss:flex"
            alt="logo"
          />
        </div>
        <h4 className="font-sora">
          {location.pathname === "/crypto"
            ? "Crypto"
            : location.pathname === "/giftcards"
            ? "Giftcards"
            : location.pathname === "/payouts"
            ? "Payouts"
            : location.pathname === "/settings"
            ? "Settings"
            : null}
        </h4>
      </div>
      <div className="flex gap-4  justify-center items-center ">
        <div className="bg-[#F1F3F5] w-[42px] h-[42px] rounded-full flex justify-center items-center">
          <IoSettingsOutline className="text-2xl lgss:text-3xl" />
        </div>
        <div className="bg-[#F1F3F5] w-[42px] h-[42px] rounded-full flex justify-center items-center">
          <IoIosNotificationsOutline className="text-2xl lgss:text-3xl" />
        </div>
        <Link
          to=""
          className="rounded-full hidden w-[42px] h-[42px] bg-[#D4EBFD] lgss:flex items-center justify-center font-medium text-secondary_button cursor-pointer"
        >
          AB
        </Link>
      </div>
    </div>
  );
};

export default Navbar;

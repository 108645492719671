import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { comingSoon } from "../assets/images";
// import { comingSoon } from "../assets/images";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";

const Dashboard = () => {
  const { token } = useAuth();
  const [viewBalance, setViewBalance] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // setLoading(false);
        setFirstName(r.data.data.user.profile.firstName);
        // if (r.data.data.user.email.isVerified === false) {
        //   navigate("/verify-mail");
        // }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token]);
  return (
    <div className="w-full font-manrope">
      <Navbar />
      <div className="lgss:flex  lgss:flex-row font-manrope bg-white lgss:bg-[#F1F3F5]  h-full">
        <Sidebar />
        <div className="w-full h-full pt-8 px-6 pb-20  font-manrope  lgss:px-12  lgss:w-4/6">
          <h2 className="font-sora text-[24px] font-bold text-[#3A4852]">
            Welcome {firstName},
          </h2>
          <div className="flex justify-between items-center mt-16">
            <h4 className="text-[#3A4852] text-[18px] font-bold ">Overview</h4>
            <div className="flex justify-center items-center gap-4 ">
              <button
                onClick={() => setViewBalance((prev) => !prev)}
                className="flex justify-center items-center gap-2 border  border-opacity-50 rounded-xl text-[#3A4852] text-[12px] border-[#617889] w-[150px] h-[45px]"
              >
                {!viewBalance ? (
                  <AiOutlineEyeInvisible className="text-2xl  text-[#3A4852]" />
                ) : (
                  <AiOutlineEye className="text-2xl  text-[#3A4852]" />
                )}
                <h4 className="font-semibold">
                  {viewBalance ? "Hide Balance" : "Show Balance"}
                </h4>
              </button>
              <select
                name=""
                id=""
                className="px-4 border bg-transparent outline-none border-opacity-50 rounded-xl font-semibold text-[#3A4852] text-[12px] border-[#617889] w-[150px] h-[45px]"
              >
                <option value="">All Time</option>
                <option value="">Today</option>
                <option value="">1 week</option>
                <option value="">1 month</option>
                <option value="">1 year</option>
              </select>
            </div>
          </div>
          <div className="grid items-center mt-10 gap-8 lgss:gap-4 justify-center grid-cols-1 lgss:grid-cols-3 ">
            <div className="h-[170px] border  border-opacity-50 lgss:border-0 shadow-md rounded-xl bg-white px-4 pt-4">
              <h4 className="text-[14px]  text-secondary_button font-semibold">
                AVAILABLE BALANCE
              </h4>
              <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
              <h2 className="text-[24px] font-sora font-bold text-[#3A4852] mt-2">
                {viewBalance ? "1000 USD" : "********"}
              </h2>
              <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[40px] bg-[#F7F8F9]">
                <h4 className="text-[13px] lgss:text-[11px] font-bold text-[#617889]">
                  {viewBalance ? "500,000 NGN" : "********"}
                </h4>
                <div className=" w-[2px] bg-[#617889] h-[30px]" />
                <h4 className="text-[13px] lgss:text-[11px] font-bold text-[#617889]">
                  {viewBalance ? "666.67 USDT" : "********"}
                </h4>
              </div>
            </div>
            <div className="h-[170px] border  border-opacity-50 lgss:border-0 shadow-md rounded-xl bg-white px-4 pt-4">
              <h4 className="text-[14px]  text-secondary_button font-semibold">
                TOTAL SALES
              </h4>
              <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
              <h2 className="text-[24px] font-sora font-bold text-[#3A4852] mt-2">
                4,336
              </h2>
              <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[40px] bg-[#F7F8F9]">
                <h4 className="text-[13px] lgss:text-[11px] font-bold text-[#617889]">
                  {viewBalance ? "456,000 NGN" : "********"}
                </h4>
                <div className=" w-[1.5px] bg-[#617889] h-[30px]" />
                <h4 className="text-[13px] lgss:text-[11px] font-bold text-[#617889]">
                  {viewBalance ? "608 USD" : "********"}
                </h4>
              </div>
            </div>
            <div className="h-[170px] border  border-opacity-50 lgss:border-0 shadow-md rounded-xl bg-white px-4 pt-4">
              <h4 className="text-[14px]  text-secondary_button font-semibold">
                PAYOUT BALANCE
              </h4>
              <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
              <h2 className="text-[24px] font-sora font-bold text-[#3A4852] mt-2">
                {viewBalance ? "1000 USD" : "********"}
              </h2>
              <div className="w-full flex px-8 lgss:px-2 justify-between items-center rounded-full mt-4 h-[40px] bg-[#F7F8F9]">
                <h4 className="text-[13px] lgss:text-[11px] font-bold text-[#617889]">
                  {viewBalance ? "500,000 NGN" : "********"}
                </h4>
                <div className=" w-[1.5px] bg-[#617889] h-[30px]" />
                <h4 className="text-[13px] lgss:text-[11px] font-bold text-[#617889]">
                  {viewBalance ? "666.67 USDT" : "********"}
                </h4>
              </div>
            </div>
          </div>
          <div className=" mt-20">
            <h2 className="font-manrope text-[24px] font-bold text-[#3A4852]">
              Earning history
            </h2>
            <div className="w-full h-[400px] mx-auto lgss:h-[600px] mb-8 px-6 lgss:px-20 lgss:mb-0 mt-10 pt-16  flex flex-col items-center justify-start  bg-white rounded-xl ">
              <img src={comingSoon} alt="" />
              <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
                Coming Soon
              </h2>
              <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
                We are cooking something great for you. Stay tuned.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full  pt-8  pb-20 lgss:h-[760px] lgss:shadow font-manrope bg-white lgss:w-1/5 ">
          <div className="w-full flex px-6 lgss:px-4 items-center   justify-center flex-col gap-10 lgss:gap-4 ">
            <div className="w-full h-[170px] lgss:h-[160px]  shadow-md rounded-xl text-white bg-[#042741] px-4 lgss:px-2 pt-4 ">
              <h4 className="text-[14px] font-bold">Set up KYC</h4>
              <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#D4EBFD]">
                Complete your KYC registration by uploading the required
                documents
              </p>
              <button className="flex mt-4 text-[12px] font-medium justify-center items-center border border-white w-11/12 mx-auto h-[40px] rounded-xl">
                Upload my documents
              </button>
            </div>
            <div className="w-full h-[170px] lgss:h-[160px] border border-opacity-50   shadow-md rounded-xl text-white bg-white px-4 lgss:px-2 pt-4 ">
              <h4 className="text-[14px] font-bold text-[#3A4852]">
                Update Business Details
              </h4>
              <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#617889]">
                Update your business profile, logo, identity, documents and
                licenses.
              </p>
              <button className="flex mt-4 text-[12px] text-secondary_button font-medium justify-center items-center border border-secondary_button w-11/12 mx-auto h-[40px] rounded-xl">
                Update business
              </button>
            </div>
            <div className="w-full h-[170px] border border-opacity-50   shadow-md rounded-xl text-white bg-white px-4 lgss:px-2 pt-4 ">
              <h4 className="text-[14px] font-bold text-[#3A4852]">
                Upgrade Account
              </h4>
              <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#617889]">
                Need more payout threshold? check for eligibility and apply for
                an upgrade
              </p>
              <button className="flex mt-4 text-[12px] text-secondary_button font-medium justify-center items-center border border-secondary_button w-11/12 mx-auto h-[40px] rounded-xl">
                Upgrade account
              </button>
            </div>
            <div className="w-full h-[170px] border border-opacity-50   shadow-md rounded-xl text-white bg-white px-4 lgss:px-2 pt-4 ">
              <h4 className="text-[14px] font-bold text-[#3A4852]">
                Refer & Earn
              </h4>
              <p className="text-[14px] lgss:text-[13px] mt-2 font-medium text-[#617889]">
                Get 10.00 USDT when you refer other businesses to use the
                DeeXoptions merchant app.
              </p>
              <button className="flex mt-4 lhs text-[12px] text-secondary_button font-medium justify-center items-center border border-secondary_button w-11/12 mx-auto h-[40px] rounded-xl">
                Go to referrals
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

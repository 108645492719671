import React from "react";
import Register from "./Register";
import { useAuth } from "../context/auth-context";
import { logo, smallLogo } from "../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import { API_URL } from "../constant";

const AuthSelect = () => {
  const location = useLocation();
  const info = location?.state;
  const navigate = useNavigate();
  const sendOtp = () => {
    axios
      .post(`${API_URL}api/auth/signin`, {
        email: info.email,
        password: info.password,
        "2faMethod": "email",
      })
      .then((r: any) => {})
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  };
  return (
    <div className="w-full lgss:h-full pt-20  flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="flex w-full pt-16 pb-10 px-6 xs:px-10 lgss:pb-0 lgss:pt-0 flex-col justify-center lgss:px-28  items-center lgss:items-start lg:px-[150px]">
        <h2 className="text-text_black  font-sora text-2xl font-bold pt-4 lgss:pt-0">
          Select Authentication
        </h2>
        {info.authApp === true && (
          <div
            className="w-full mt-6 cursor-pointer"
            onClick={() =>
              navigate("/2fa-auth-app", {
                state: {
                  email: info.email,
                  from: info.from,
                  password: info.password,
                  authApp: info.authApp,
                  emailAuth: info.emailAuth,
                },
              })
            }
          >
            <div className="w-full flex justify-between items-center">
              <div className="w-4/5 md:w-3/5">
                <h4 className="text-text_black  font-sora text-[16px] font-semibold pt-4 lgss:pt-0">
                  Google Authenticator
                </h4>
                <h4 className="text-[#617889] mt-1  font-manrope text-[13px] font-semibold pt-4 lgss:pt-0">
                  Send a one time password (OTP) to your google authenticator
                  app
                </h4>
              </div>
              <BsArrowRight className="text-2xl text-paragraph " />
            </div>
            <div className="rounded-full w-full h-[1px] bg-[#AEBCC6] mt-4" />
          </div>
        )}
        {info.emailAuth === true && (
          <div
            className="w-full mt-6 cursor-pointer"
            onClick={() => {
              sendOtp();
              navigate("/2fa-auth", {
                state: {
                  email: info.email,
                  from: info.from,
                  password: info.password,
                  authApp: info.authApp,
                  emailAuth: info.emailAuth,
                },
              });
            }}
          >
            <div className="w-full flex justify-between items-center">
              <div className="w-4/5 md:w-3/5">
                <h4 className="text-text_black  font-sora text-[16px] font-semibold pt-4 lgss:pt-0">
                  Email
                </h4>
                <h4 className="text-[#617889] mt-1 font-manrope text-[13px] font-semibold pt-4 lgss:pt-0">
                  Send a one time password (OTP) to your email {info.email}.
                </h4>
              </div>
              <BsArrowRight className="text-2xl text-paragraph " />
            </div>
            <div className="rounded-full w-full h-[1px] bg-[#AEBCC6]  mt-4" />
          </div>
        )}
      </div>
    </div>
  );
};

const AuthSelectPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen ">
    <Register />
    <AuthSelect />
  </div>
);

export default AuthSelectPage;

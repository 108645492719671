import React, { FC, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { BiErrorCircle } from "react-icons/bi";
import Register from "./Register";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../constant";
import axios from "axios";
import { useAuth } from "../context/auth-context";
import { smallLogo } from "../assets/images";

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setResetEmail } = useAuth();
  const validateEmail = (email: string) => {
    // check if email is empty
    if (!email) {
      setError("kindly add your email");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }
    // check if email is in the correct format using a regular expression
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      setError("Your email is not in the correct format");
      setTimeout(() => {
        setError("");
      }, 6000);
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      setResetEmail(email);
      axios
        .post(`${API_URL}api/auth/forgot-password`, {
          email,
        })
        .then((r: any) => {
          navigate("/reset-password");
          setLoading(false);
          setEmail("");
          setTimeout(() => {
            setError("");
          }, 6000);

          console.log(r.data.message);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
  };

  return (
    <div className="w-full font-manrope lgss:h-full lgss:bg-[#C6D0D7] pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="w-full px-8 lgss:px-24 pt-16 lgss:pt-0">
        <h2 className="text-text_black font-sora text-2xl font-bold pt-4 lgss:pt-0">
          👀 Forgot Password
        </h2>
        <h4 className="font-manrope text-text_black font-medium text-sm pt-6">
          Confirm your email and we’d send you instructions
        </h4>
        <div className="lgss:h-[270px] lgss:mt-8 lgss:px-8  w-full lgss:w-4/5 lgss:rounded-xl lgss:bg-white">
          <form onSubmit={handleSubmit} className="mt-2 w-full ">
            <label className="relative">
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                  setResetEmail(e.target.value.toLowerCase());
                }}
                name="email"
                className="h-[50px] w-full mt-8  px-[16px] font-manrope bg-[#F7F8F9] border rounded-lg border-[#C6D0D7] outline-none focus:border-secondary_button text-[#AEBCC6] placeholder:text-[#AEBCC6] transition duration-200"
              />
              <span className="text-base  mt-8 text-[#4A4A68] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                Email
              </span>
            </label>

            {error && (
              <div className="bg-red-600 text-white text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
                <BiErrorCircle className="text-xl" />
                {error}
              </div>
            )}
            <button
              type="submit"
              className="w-full h-[60px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Send"
              )}
            </button>
          </form>
          <h4 className="font-manrope  text-text_black font-medium text-sm pt-6">
            Don’t have an account?{" "}
            <Link
              to="/signup"
              className="underline text-secondary_button cursor-pointer"
            >
              Sign Up
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};
const ForgotPasswordPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <ForgotPassword />
  </div>
);
export default ForgotPasswordPage;

import React from "react";
import { Link } from "react-router-dom";
import { smallLogo } from "../assets/images";
import Register from "./Register";

const AuthOnboarding = () => {
  return (
    <div className="w-full font-manrope lgss:h-full lgss:bg-[#C6D0D7] pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="w-full flex flex-col justify-start items-center lgss:w-3/5 px-8 pt-24 lgss:pt-6 lgss:px-6 lgss:mx-auto lgss:bg-white lgss:rounded-xl lgss:h-[200px]">
        <h4 className="text-[#13181B] text-[24px] font-bold font-sora">
          Sign Up
        </h4>
        <p className="text-[15px] lgss:mt-1 font-medium text-[#3A4852] mt-4">
          Do you have a DeeXoption account?
        </p>
        <div className="flex w-full gap-6 justify-center mt-8 lgss:mt-6 items-center flex-col lgss:flex-row">
          <Link
            to="/signup"
            className="w-full font-semibold text-secondary_button text-[16px] border border-secondary_button lgss:w-1/2 rounded-xl h-[48px] flex justify-center items-center"
          >
            No, create an account
          </Link>
          <Link
            to="/login"
            className="w-full font-semibold text-white text-[16px]  bg-secondary_button lgss:w-1/2 rounded-xl h-[48px] flex justify-center items-center"
          >
            Yes, I have an account
          </Link>
        </div>
      </div>
    </div>
  );
};

const AuthOnboardingPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <AuthOnboarding />
  </div>
);

export default AuthOnboardingPage;
